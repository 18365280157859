import React, { useContext, useEffect, useState } from "react";
import "./Relatorio.css";
import { Col, Row, Button } from "antd";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import os_icon from "../../../../assets/images/OS.png";
import money_bag from "../../../../assets/images/money_bag.png";
import hand_money from "../../../../assets/images/hand_money.png";
import card from "../../../../assets/images/card.png";
import pix_icon from "../../../../assets/images/pix.png";
import credito_icon from "../../../../assets/images/credito.png";
import debito_icon from "../../../../assets/images/debito.png";
import especie_icon from "../../../../assets/images/especie.png";
import * as links from "../../../../utils/links";
import moment from "moment";
import { LucideArrowLeftToLine } from "lucide-react";
import dayjs from "dayjs";

const Relatorio = (props) => {
	const { authInfo, setNotiMessage } = useContext(AuthContext);
	const { dataUser } = authInfo;
	let navigate = useNavigate();
	const location = useLocation();

	const { maquinaInfos, dataInicio, dataFim } = location.state;

	const { id } = useParams();

	const token = authInfo?.dataUser?.token;

	const [estornos, setEstornos] = useState({ SOMA: "0" });
	const [pagamentos, setPagamentos] = useState({
		PIX: "0",
		ESPECIE: "0",
		CREDITO: "0",
		DEBITO: "0",
	});
	const [taxas, setTaxas] = useState({
		PIX: "0",
		CREDITO: "0",
		DEBITO: "0",
	});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		dataData();

		const intervalId = setInterval(() => {
			dataData();
		}, 60000);

		return () => clearInterval(intervalId);
	}, []);

	const dataData = async () => {
		try {
			setIsLoading(true);
			const dataInicioUTC = moment(dataInicio, "YYYY-MM-DD").startOf("day").toISOString();
			const dataFimUTC = moment(dataFim, "YYYY-MM-DD").endOf("day").toISOString();
			const body = { maquinaId: id, dataInicio: dataInicioUTC, dataFim: dataFimUTC };
			// body.dataInicio = new Date(`${dataInicio.slice(0, 11)}T00:00:00.000Z`);
			// body.dataFim = new Date(`${dataFim.slice(0, 11)}T23:59:00.000Z`);

			const [estornosVal, taxasVal, pagamentosVal, cashVal] = await Promise.all([
				axios.post(`${process.env.REACT_APP_SERVIDOR}/relatorio-04-estornos`, body, {
					headers: {
						"x-access-token": token,
						"content-type": "application/json",
					},
				}),
				axios.post(`${process.env.REACT_APP_SERVIDOR}/relatorio-02-taxas`, body, {
					headers: {
						"x-access-token": token,
						"content-type": "application/json",
					},
				}),
				axios.post(`${process.env.REACT_APP_SERVIDOR}/relatorio-03-pagamentos`, body, {
					headers: {
						"x-access-token": token,
						"content-type": "application/json",
					},
				}),
				axios.post(`${process.env.REACT_APP_SERVIDOR}/relatorio-01-cash`, body, {
					headers: {
						"x-access-token": token,
						"content-type": "application/json",
					},
				}),
			]);
			setEstornos({
				SOMA: estornosVal?.data?.valor || "0",
			});

			setTaxas({
				PIX: taxasVal.data?.pix || "0",
				CREDITO: taxasVal.data?.credito || "0",
				DEBITO: taxasVal.data?.debito || "0",
			});

			setPagamentos({
				PIX: pagamentosVal.data.pix || "0",
				CREDITO: pagamentosVal.data.credito || "0",
				DEBITO: pagamentosVal.data.debito || "0",
				ESPECIE: cashVal.data?.valor || "0",
			});

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);

			setNotiMessage({
				type: "error",
				message: "A sua sessão expirou, para continuar faça login novamente.",
			});
		}
	};

	return (
		<div>
			{isLoading && <LoadingAction />}
			<div className="Cliente_WarningMsgSpan">
				<span>{dataUser.warningMsg}</span>
			</div>
			<div className="flex flex-col gap-4 px-4">
				<div className=" gap-4 flex flex-col">
					<div className="font-bold text-center">
						<span className="text-lg">Relatório Máquina</span>
						<span>{id}</span>
					</div>
					<div className="flex flex-col font-bold text-center">
						<span>
							{dayjs(dataInicio).format("DD/MM/YYYY")} - {dayjs(dataFim).format("DD/MM/YYYY")}
						</span>
						<span>Máquina: {maquinaInfos.nome}</span>
					</div>
					<div className="text-center text-base">Gerado em: {moment(new Date()).format("DD/MM/YYYY HH:mm")}</div>
				</div>
				<button
					type="button"
					className="p-2 flex gap-2 items-center m-auto w-full md:w-1/3 justify-center border-slate-500 border shadow rounded-lg max-w-lg"
					onClick={() => {
						navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
							state: maquinaInfos,
						});
					}}
				>
					<LucideArrowLeftToLine className="h-4 w-4" />
					<span>VOLTAR</span>
				</button>
			</div>

			<div className="flex flex-col justify-center items-center">
				<div className="flex gap-1 justify-between max-w-4xl w-full">
					<div className="Relatorio w-full max-w-4xl">
						<div className="flex flex-col w-full gap-x-2.5">
							<div className="Relatorio_title text-center">Pagamentos</div>
							<div className="flex justify-between">
								<div className="flex flex-col gap-4 justify-between">
									<div className="flex gap-1 justify-between">
										<div className="flex gap-1">
											<img className="" src={pix_icon} alt="PIX" />
											PIX
										</div>
										<span className="ml-2">
											{new Intl.NumberFormat("pt-BR", {
												style: "currency",
												currency: "BRL",
											}).format(pagamentos?.PIX)}
										</span>
									</div>
									<div className="flex gap-1 justify-between">
										<div className="flex gap-1">
											<img className="" src={especie_icon} alt="ESPÉCIE" />
											ESPÉCIE
										</div>
										<span className="ml-2">
											{new Intl.NumberFormat("pt-BR", {
												style: "currency",
												currency: "BRL",
											}).format(pagamentos?.ESPECIE)}
										</span>
									</div>
									<div className="flex gap-1 justify-between">
										<div className="flex gap-1">
											<img className="" src={credito_icon} alt="CRÉDITO" />
											CRÉDITO
										</div>
										<span className="ml-2">
											{new Intl.NumberFormat("pt-BR", {
												style: "currency",
												currency: "BRL",
											}).format(pagamentos?.CREDITO)}
										</span>
									</div>
									<div className="flex gap-1 justify-between">
										<div className="flex gap-1">
											<img className="" src={debito_icon} alt="DÉBITO" />
											DÉBITO
										</div>
										<span className="ml-2">
											{new Intl.NumberFormat("pt-BR", {
												style: "currency",
												currency: "BRL",
											}).format(pagamentos?.DEBITO)}
										</span>
									</div>
								</div>
								<div className="flex justify-center items-center h-full">
									<img className="h-2/3" src={money_bag} alt="os icon" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-center items-center">
				<div className="flex gap-1 justify-between max-w-4xl w-full">
					<div className="Relatorio w-full flex-col">
						<div className="Relatorio_title text-center">Estornos</div>
						<div className="w-full flex items-center">
							<div className="flex flex-col w-full gap-x-2.5">
								<div className="flex justify-between">
									<div className="flex flex-col gap-4 justify-between">
										<div className="flex gap-1 justify-between">
											<div className="flex gap-1">
												<img className="" src={pix_icon} alt="PIX" />
												TOTAL DE ESTORNOS
											</div>
											<span className="ml-2">{estornos?.SOMA}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="flex justify-center items-center h-full">
								<img className="Relatorio_money_icon" src={card} alt="os icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-center items-center">
				<div className="flex gap-1 justify-between max-w-4xl w-full">
					<div className="Relatorio w-full flex-col">
						<div className="Relatorio_title text-center">Taxas</div>
						<div className="w-full flex items-center">
							<div className="flex flex-col w-full gap-x-2.5">
								<div className="flex justify-between">
									<div className="flex flex-col gap-4 justify-between">
										<div className="flex gap-1 justify-between">
											<div className="flex gap-1">
												<img src={pix_icon} alt="PIX" />
												PIX
											</div>
											<span className="ml-2">{Number(taxas?.PIX).toFixed(2)}</span>
										</div>
										<div className="flex gap-1 justify-between">
											<div className="flex gap-1">
												<img src={credito_icon} alt="CRÉDITO" />
												CRÉDITO
											</div>
											<span className="ml-2">{Number(taxas?.CREDITO).toFixed(2)}</span>
										</div>
										<div className="flex gap-1 justify-between">
											<div className="flex gap-1">
												<img src={debito_icon} alt="DÉBITO" />
												DÉBITO
											</div>
											<span className="ml-2">{Number(taxas?.DEBITO).toFixed(2)}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="flex justify-center items-center h-full">
								<img className="h-2/3" src={hand_money} alt="os icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Relatorio;
