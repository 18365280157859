import { faArrowsRotate, faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import LoadingAction from "../../../themes/LoadingAction/LoadingAction";
import * as links from "../../../utils/links";
import "./Dashboard.css";
import { CircleCheckBig, LucideWifiOff } from "lucide-react";
import { TIME_TO_AUTO_UPDATE } from "../../../utils/constants";

const DashboardFornecedor = (props) => {
	const { setDataUser, loading, authInfo, setNotiMessage } = useContext(AuthContext);
	const { dataUser } = authInfo;
	let navigate = useNavigate();
	const token = authInfo?.dataUser?.token;
	const premiumExpiration = authInfo?.dataUser?.premiumExpiration ?? null;
	const hasData = !!authInfo?.dataUser?.hasData;
	const [favorites, setFavorites] = useState([]);
	const [meusFits, setMeusFits] = useState(null);
	const [totalCanais, setTotalCanais] = useState(null);
	const [totalFornecedores, setTotalFornecedores] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [dataCurrentDetail, setDataCurrentDetail] = useState(null);

	// useEffect(() => {
	//     dataData();
	// }, [])

	useEffect(() => {
		dataData();

		const intervalId = setInterval(() => {
			dataData();
		}, TIME_TO_AUTO_UPDATE);

		// Limpar o intervalo quando o componente for desmontado para evitar vazamento de memória
		return () => clearInterval(intervalId);
	}, []);

	const dataData = () => {
		setIsLoading(true);
		axios
			.get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
				headers: {
					"x-access-token": token,
					"content-type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setIsLoading(false);
					setTotalFornecedores(res.data);
				} else {
					throw new Error();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				if ([401, 403].includes(err.response.status)) {
					// setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
					setNotiMessage({
						type: "error",
						message: "A sua sessão expirou, para continuar faça login novamente.",
					});
					setDataUser(null);
				}
			});
	};

	const handleMaquinaClick = (id, nome, storeId, pulso, estoque, descricao) => {
		const maquinaInfos = { nome, storeId, pulso, estoque, descricao };
		navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
			state: maquinaInfos,
		});
	};

	return (
		<div className="px-2">
			{isLoading && <LoadingAction />}
			{/* <div className="WarningMsg">
                    {dataUser.warningMsg}
                </div> */}
			<div className="WarningMsgSpan">
				<span>{dataUser.warningMsg}</span>
			</div>
			<div className="flex justify-center p-2">
				<div className="text-3xl font-bold">Monitoramento</div>
				{/*<Button className="Dashboard_addbtn">*/}
				{/*    <AiOutlinePlusCircle />*/}
				{/*    <span>Addcionar Máquina</span>*/}
				{/*</Button>*/}
			</div>
			<div className="flex justify-center gap-2 px-2">
				<button
					className="max-w-xs gap-2 justify-center items-center flex flex-1 border-2 border-slate-200 p-2 shadow-lg hover:opacity-90"
					onClick={dataData}
				>
					<FontAwesomeIcon icon={faArrowsRotate} className="h-4 w-4" />
					Atualizar
				</button>
				<Link
					className="flex max-w-xs flex-1 justify-center border-2 border-slate-200 p-2 shadow-lg hover:flex hover:opacity-90"
					to={links.ADD_MACHINE}
				>
					<button className="flex items-center gap-2">
						<AiOutlinePlusCircle className="h-4 w-4" />
						<span>Adicionar Máquina</span>
					</button>
				</Link>
			</div>
			<div className="grid w-full grid-cols-1 gap-2 md:gap-4 md:grid-cols-3">
				{/* <div className="grid w-full grid-cols-1 md:grid-cols-3"> */}
				{totalFornecedores.map((post) => (
					<div className="" key={post.id}>
						<div
							className="maquina cursor-pointer"
							onClick={() =>
								handleMaquinaClick(post.id, post.nome, post.store_id, post.pulso, post.estoque, post.descricao)
							}
						>
							<div className="flex flex-col gap-4 p-2">
								<div className="flex items-center justify-between">
									<h2 className="m-0 font-bold">{post.nome}</h2>
									{(() => {
										switch (post.status) {
											case "ONLINE":
												return (
													<CircleCheckBig color="green" className="h-6 w-6" />

													// <FontAwesomeIcon
													//   icon={faCheckCircle}
													//   color={"green"}
													//   className="logout-icon fa-3x"
													// />
												);
											case "OFFLINE":
												return (
													<LucideWifiOff color="red" className="h-6 w-6" />
													// <FontAwesomeIcon
													//   icon={faXmarkCircle}
													//   color={"red"}
													//   className="logout-icon fa-3x"
													// />
												);
											case "PAGAMENTO_RECENTE":
												return (
													<CircleCheckBig color="blue" className="h-6 w-6" />
													// <FontAwesomeIcon
													//   icon={faCheckCircle}
													//   color={"blue"}
													//   className="logout-icon fa-3x"
													// />
												);
											default:
												return null;
										}
									})()}
								</div>
								<h4>
									{post.status} - {post.descricao}
								</h4>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default DashboardFornecedor;
